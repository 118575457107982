// Angular
import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
// Components
import {BaseComponent} from './views/theme/base/base.component';
import {ErrorPageComponent} from './views/theme/content/error-page/error-page.component';
// Auth
import {AuthGuard} from './core/_guard/AuthGuard';
import {HomeComponent} from './views/pages/home/home.component'

// Authfake
//import {AuthGuard} from './core/auth';

const routes: Routes = [
	{path: 'auth',
	canActivate: [AuthGuard],
	data: { roles: "auth" } ,
	loadChildren: () => import('app/views/pages/auth/auth.module').then(m => m.AuthModule)},
	{
		path: 'offre',
		loadChildren: () => import('app/views/pages/offre/offre.module').then(m => m.OffreModule),
	},
     //currentUser
	 {
		path: 'home',
		component: HomeComponent,
	},
	{
		path: '',
		component: BaseComponent,
		canActivate: [AuthGuard],
		data: { roles: "currentUser" } ,
		children: [
			{
				path: 'dashboard',
				loadChildren: () => import('app/views/pages/dashboard/dashboard.module').then(m => m.DashboardModule),
			},

			{
				path: 'error/403',
				component: ErrorPageComponent,
				data: {
					type: 'error-v6',
					code: 403,
					title: '403... Access forbidden',
					desc: 'Looks like you don\'t have permission to access for requested page.<br> Please, contact administrator',
				},
			},
			{path: 'error/:type', component: ErrorPageComponent},
			{path: '', redirectTo: 'dashboard', pathMatch: 'full'},
			{path: '**', redirectTo: 'dashboard', pathMatch: 'full'},
		],
	},
	

	{path: '**', redirectTo: 'error/403', pathMatch: 'full'},
];

@NgModule({
	imports: [
		RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' }),
	],
	exports: [RouterModule],
})
export class AppRoutingModule {
}
