import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';
import { AuthService } from '../../service/auth.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    constructor() { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // add authorization header with jwt token if available
        let currentUser = JSON.parse(localStorage.getItem('currentUser'));
        let offre = JSON.parse(localStorage.getItem('offre'));
        let CryptageKeys = JSON.parse(localStorage.getItem('CryptageKeys'));
        let complement = JSON.parse(localStorage.getItem('complement'));
       // console.log(currentUser.token);
       //console.log(request.url);
        if (currentUser && currentUser.token) {
            request = request.clone({
                setHeaders: { 
                    Authorization: `Bearer ${currentUser.token}`
                }

                
            });
           // console.log(request);

        }else if(offre && offre.token) {

            request = request.clone({
                setHeaders: { 
                    Authorization: `Bearer ${offre.token}`
                }

                
            });
        }else if(complement && complement.token) {

            request = request.clone({
                setHeaders: { 
                    Authorization: `Bearer ${complement.token}`
                }

                
            });
        }else if(CryptageKeys && CryptageKeys.token) {

            request = request.clone({
                setHeaders: { 
                    Authorization: `Bearer ${CryptageKeys.token}`
                }

                
            });
        }

        return next.handle(request);
    }


   
      
}
