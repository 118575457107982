import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment'
import { Utilisateurs } from '../core/_model/utilisateurs'
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UtilisateurServiceService {

  constructor(private http: HttpClient) { }

  getAll(): Observable<any> {
    return this.http.get<Utilisateurs[]>(environment.apiUrl + `/api/user/listusers`);
  }

  adduser(user: any): Observable<any> {
    return this.http.post(environment.apiUrl + '/api/user/create', user, { responseType: 'text' });

  }


  deleteuser(id: number): Observable<any> {
    console.log("delete get id : " + id)
    return this.http.delete(environment.apiUrl + '/api/user/delete/' + id, { responseType: 'text' });
  }

  findUserById(id: number): Observable<any> {
    console.log("findUserById  : " + id)
    return this.http.get<Utilisateurs[]>(environment.apiUrl + `/api/user/find/` + id);
  }

  edituser(user: any): Observable<any> {
    return this.http.put(environment.apiUrl + '/api/user/update', user, { responseType: 'text' });
  }
  edituseretat(id,etat) {
    return this.http.get(environment.apiUrl + `/api/user/activation/res?idUser=` + id+"&etat="+etat, { responseType: 'text' });
  }

  getrole(username){
    return this.http.get<any>(environment.apiUrl + `/api/user/role/` + username);
  }

}
