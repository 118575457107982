
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';


//import { environment } from '../../environments/environment';
//import { User } from '../core/_model/user';

@Injectable({ providedIn: 'root' })
export class AuthService {
  /* private currentUserSubject: BehaviorSubject<User>;
   public currentUser: Observable<User>;*/

  constructor(private http: HttpClient) {
  }



  login(username: string, password: string) {
    const optionRequete = {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        'mon-entete-personnalise': 'maValeur'
      })
    };
    return this.http.post<any>(environment.apiUrl + `/api/auth/signin`, { username, password }, optionRequete)
      .pipe(map(user => {
        console.log(user);
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        localStorage.setItem('currentUser', JSON.stringify(user));
        localStorage.setItem('username', username);

        let jwt = JSON.stringify(user.token)
        let jwtData = jwt.split('.')[1]
        let decodedJwtJsonData = window.atob(jwtData)
        let decodedJwtData = JSON.parse(decodedJwtJsonData)

        let isAdmin = decodedJwtData.admin

        console.log('jwtData: ' + jwtData)
        console.log('decodedJwtJsonData: ' + decodedJwtJsonData)
        console.log('decodedJwtData: ' + decodedJwtData)
        console.log('Is admin: ' + isAdmin)

        //this.currentUserSubject.next(user);
        return user;
      }));
  }
  loginOffre(username: string, password: string) {
    const optionRequete = {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        'mon-entete-personnalise': 'maValeur'
      })
    };
    return this.http.post<any>(environment.apiUrl + `/api/auth/offre`, { username, password }, optionRequete)
      .pipe(map(user => {
        console.log(user);
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        localStorage.setItem('offre', JSON.stringify(user));
        localStorage.setItem('token', username);
        //this.currentUserSubject.next(user);
        return user;
      }));
  }
  loginComplement(username: string, password: string) {
    const optionRequete = {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        'mon-entete-personnalise': 'maValeur'
      })
    };
    return this.http.post<any>(environment.apiUrl + `/api/auth/complement`, { username, password }, optionRequete)
      .pipe(map(user => {
        console.log(user);
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        localStorage.setItem('complement', JSON.stringify(user));
        localStorage.setItem('token', username);
        //this.currentUserSubject.next(user);
        return user;
      }));
  }
  loginotp(username: string, password: string, token: any): Observable<any> {
    const optionRequete = {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        'mon-entete-personnalise': 'maValeur'
      })
    };
    return this.http.post(environment.apiUrl + `/api/auth/otp/` + token, { username, password })
      .pipe(map(user => {
        console.log(user);
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        // localStorage.setItem('complement', JSON.stringify(user));
        localStorage.setItem('token', username);
        //
        //this.currentUserSubject.next(user);
        return user;
      }));


    /*let promise = new Promise((resolve, reject) => {
      this.http.post(environment.apiUrl+`/api/auth/otp`, { username, password },{responseType: 'text'})        .toPromise()
      
        .then(
          res => { // Success
            console.log(res);
            resolve();
          }
        );
    });
    return promise;*/
  }

  verifycode(sessionId: string, codeVerification: string, token: string): Observable<any> {
    const optionRequete = {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        'mon-entete-personnalise': 'maValeur'
      })
    };
    return this.http.post(environment.apiUrl + `/api/auth/verifycode/` + token, { sessionId, codeVerification })
      .pipe(map(data => {
        console.log(data);
        localStorage.setItem('CryptageKeys', JSON.stringify(data));
        localStorage.setItem('token', token);

        return data;
      }));
  }

  verifytoken(token: string): Observable<any> {
    const optionRequete = {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        'mon-entete-personnalise': 'maValeur'
      })
    };
    return this.http.get(environment.apiUrl + `/api/auth/verifytoken/` + token)
      .pipe(map(data => {
        localStorage.setItem('CryptageKeys', JSON.stringify(data));
        localStorage.setItem('token', token);
        return data;
      }));
  }

  refreshtoken(): Observable<any> {
    const optionRequete = {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        'mon-entete-personnalise': 'maValeur'
      })
    };
    return this.http.get(environment.apiUrl + `/api/auth/refreshtoken`)
      .pipe(map(data => {
        let currentUser = JSON.parse(localStorage.getItem('currentUser'));
        let offre = JSON.parse(localStorage.getItem('offre'));
        let CryptageKeys = JSON.parse(localStorage.getItem('CryptageKeys'));
        let complement = JSON.parse(localStorage.getItem('complement'));
        if (currentUser && currentUser.token) {
          localStorage.setItem('currentUser', JSON.stringify(data));
        } else if (offre && offre.token) {
          localStorage.setItem('offre', JSON.stringify(data));
        } else if (complement && complement.token) {
          localStorage.setItem('complement', JSON.stringify(data));
        } else if (CryptageKeys && CryptageKeys.token) {
          localStorage.setItem('CryptageKeys', JSON.stringify(data));
        }
        return data;
      }));
  }



  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
    localStorage.removeItem('username');
    localStorage.removeItem('roles');

    // this.currentUserSubject.next(null);
  }


}