import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';


@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    constructor(
        private router: Router,
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        //const currentUser = localStorage.getItem("currentUser");
       
        if(route.data.roles =="auth")
        {
            this.logout()
            return true;

        }else{
            const jwt = localStorage.getItem(route.data.roles);
            if (jwt) {
                // logged in so return true
                return true;
            }
            // not logged in so redirect to login page with the return url
            this.router.navigateByUrl("/home");
            return false;
       }
    }

  
   /*
      canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        //const currentUser = localStorage.getItem("currentUser");



        if(route.data.roles =="auth")
        {
            console.log(route.data.roles);
            if(localStorage.getItem("currentUser")!=null){
                this.router.navigateByUrl("/");
                return false;
            }
            else if(localStorage.getItem("offre")!=null){
                 let token  = localStorage.getItem("token");

                this.router.navigate(['offre/depot/', token]);
                return false;

            }
            else if(localStorage.getItem("complement")!=null){
                let token  = localStorage.getItem("token");
               this.router.navigate(['offre/complement/', token]);
               return false;

           }else{
            return true;
           }       
        }else{
            const jwt = localStorage.getItem(route.data.roles);
            if (jwt) {
                // logged in so return true
                return true;
            }
            // not logged in so redirect to login page with the return url
            this.router.navigateByUrl("/home");
            return false;
       }
    }*/
    
    logout(){
        localStorage.removeItem('currentUser');
        localStorage.removeItem('username');
        localStorage.removeItem('roles');  
        localStorage.removeItem('complement');
        localStorage.removeItem('token');            
        localStorage.removeItem('offre');
    }

}

