import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { LayoutUtilsService } from '../_base/crud';
import { Router } from '@angular/router';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(
                private layoutUtilsService: LayoutUtilsService,
                private router: Router
                ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(err => {
            console.log(err)
            if (err.status === 401) {
                console.log(err)

               /* if(localStorage.getItem('currentUser')!=null)
                { 
                    localStorage.removeItem('currentUser');
                    localStorage.removeItem('username');

                  }
                
                else if(localStorage.getItem('complement')!=null) {
                    localStorage.removeItem('complement');
                    localStorage.removeItem('token');
                }
                else if(localStorage.getItem('offre')!=null) {
                    localStorage.removeItem('offre');
                    localStorage.removeItem('token');}*/
                const dialogRef = this.layoutUtilsService.fetchElements("Session Expired", "Your session is expired", 2);
                dialogRef.afterClosed().subscribe(res => {
                  if (!res) {
                    if(localStorage.getItem('currentUser')!=null)
                    { 
                        localStorage.removeItem('currentUser');
                        localStorage.removeItem('username');

                        this.router.navigate(['auth/login']);

                      }
                    
                    else if(localStorage.getItem('complement')!=null) {
                        var tokencomplement =  localStorage.getItem('token')
                        localStorage.removeItem('complement');
                        localStorage.removeItem('token');
                        this.router.navigate(['auth/token/', tokencomplement]);

                    }
                    else if(localStorage.getItem('offre')!=null) {
                        var tokenoffre =  localStorage.getItem('token')
                        localStorage.removeItem('offre');
                        localStorage.removeItem('token');}
                        this.router.navigate(['auth/token/', tokenoffre]);

                    return;
                  }
                });
            }

            const error = err.error.message || err.statusText;
            return throwError(err);
        }))
    }
}
