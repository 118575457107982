

export class MenuConfig {
	public defaults: any = {
		header: {
			self: {},
			items: [

			]
		},
		aside: {
			self: {},
			items: [
				{
					title: 'Tableau de bord',
					root: true,
					icon: 'flaticon2-architecture-and-city',
					page: '/dashboard',
					translate: 'MENU.DASHBOARD',
					bullet: 'dot',
					permissions : ['ROLE_Admin', 'ROLE_Commercial'],
				},

				{section: '   '},
				{
					title: 'AOR',
					root: true,
					page: '/dashboard/consultations',
					bullet: 'dot',
					icon: 'flaticon2-browser-2',
					permissions : ['ROLE_Admin', 'ROLE_Commercial'],


				},
				{
					title: 'Fournisseurs',
					root: true,
					page: '/dashboard/enterprises',
					bullet: 'dot',
					icon: 'flaticon2-digital-marketing',
					permissions : ['ROLE_Admin', 'ROLE_Commercial'],


				},


				{
					title: 'Utilisateurs',
					root: true,
					page: '/dashboard/utilisateurs',
					bullet: 'dot',
					icon: 'flaticon2-user-outline-symbol',
					permissions : ['ROLE_Admin'],


				},
				{
					title: 'Produits',
					root: true,
					page: '/dashboard/produits',
					bullet: 'dot',
					icon: 'flaticon2-open-box',
					permissions : ['ROLE_Admin', 'ROLE_Commercial'],


				},
				{
					title: 'Clés de décryptage',
					root: true,
					page: '/dashboard/CryptageKeys',
					bullet: 'dot',
					icon: 'flaticon2-lock',
					permissions : ['ROLE_Admin'],


				},
				//CryptageKeysComponent

				{
					title: 'Rapports / Statistiques',
					root: true,
					bullet: 'dot',
					icon: 'flaticon2-list-2',
					permissions : ['ROLE_Admin', 'ROLE_Commercial'],
					submenu: [
						{
							title: 'Téléchargement',
							bullet: 'dot',
							icon: 'flaticon2-list-2',
							page: '/dashboard/rapportStatistique/ListeTelechargement',
						},
						{
							title: 'Dépôt des offres',
							bullet: 'dot',
							icon: 'flaticon2-expand',
							page: '/dashboard/rapportStatistique/ListeDepot',
						},
						{
							title: 'Transactions',
							bullet: 'dot',
							icon: 'flaticon2-mail-1',
							page: '/dashboard/rapportStatistique/ListeTransactions',
						},
					]

				},

			]
		},
	};

	public get configs(): any {
		return this.defaults;
	}
}
