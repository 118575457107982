// Angular
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
// RxJS
import {AuthService} from '../../../../../service/auth.service'
@Component({
	selector: 'kt-user-profile',
	templateUrl: './user-profile.component.html',
})
export class UserProfileComponent implements OnInit {
	// Public properties
	_user : any = {
		pic : './assets/media/users/default.jpg'
		
	}

	@Input() avatar = true;
	@Input() greeting = true;
	@Input() badge: boolean;
	@Input() icon: boolean;
	login:any=""
	/**
	 * Component constructor
	 *@parm AuthService
	 * 
	 */
	constructor(private authService : AuthService,private router: Router) {
	}

	/**
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	 */

	/**
	 * On init
	 */
	ngOnInit(): void {
		//this.user$ = this.store.pipe(select(currentUser));
		this.login=localStorage.getItem('username');
	}

	/**
	 * Log out
	 */
	logout() {
		this.authService.logout();
		this.router.navigate(['auth/login']);
	}
}
